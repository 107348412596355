<template>
    <div class="animated fadeIn">
        <CRow>
            <CCol col="2" lg="2" sm="2">
                <CWidgetIcon :header="active_user" text="Active User" color="primary">
                    <CIcon name="cil-user"/>
                    <CElementCover :opacity="0.8" v-if="active_user_loading"/>
                </CWidgetIcon>
            </CCol>
            <CCol col="7" lg="7" sm="7">
                <CRow>
                    <CCol col="7">
                        <label>
                            Transaction Date Range:
                        </label>
                        <CDateRange ref="_CDateRange" />
                    </CCol>
                    <CCol col="3">
                        <label>
                            Category
                        </label>
                        <v-select label="Selected Category" :options="category" :clearable="false"
                            v-model="selected_category" />
                    </CCol>
                    <CCol col="2" style="padding:0px 2px 0px 2px;">
                        <CButton style="margin-top:30px" color="primary" title="Advance Filter"
                            @click="searchBillingReport()" :disabled="submit_filter ? true : false">
                            <font-awesome-icon :icon="submit_filter ? 'circle-notch' : 'search'"
                                :pulse="submit_filter ? true : false" />
                            Search
                        </CButton>
                    </CCol>
                </CRow>
            </CCol>
            <CCol col="3" lg="3" sm="3">
                <CRow>
                    <CCol col="6" style="padding:0px 2px 0px 2px;">
                        <CButton style="margin-top:30px;" color="warning" title="Advance Filter" @click="removeFilter()"
                            :disabled="remove_filter ? true : false">
                            <font-awesome-icon :icon="remove_filter ? 'circle-notch' : 'eraser'"
                                :pulse="remove_filter ? true : false" />
                            Remove Filter
                        </CButton>
                    </CCol>
                    <CCol col="6" style="padding:0px 2px 0px 2px;">
                        <CButton style="margin-top:30px;" type="submit" color="success" @click="BillingReportExport"
                            :disabled="exporting_excel ? true : false">
                            <font-awesome-icon :icon="exporting_excel ? 'circle-notch' : 'file-excel'"
                                :pulse="exporting_excel ? true : false" />
                            Export to Excel
                        </CButton>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
        <CCard>
            <CCardHeader><font-awesome-icon icon="rectangle-list"/> Billing</CCardHeader>
            <CCardBody>
                <CDataTable :items="billing_report_list" :fields="billing_report_list_fields"
                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:' }" :items-per-page="10" border
                    items-per-page-select sorter pagination hover :loading="this.table_loading">
                    <template slot="no-items-view" v-if="this.table_loading">
                        <center>
                            <CSpinner style="width:4rem;height:4rem;" color="info" />
                        </center>
                    </template>

                    <template #is_active="{ item }">
                        <td>
                        <CBadge 
                            v-show="item.is_active == 'Active'"
                            :color="getColorStatus('Active')">
                            {{item.is_active}}  
                        </CBadge>
                        <CBadge 
                             v-show="item.is_active == 'Not Active'"
                            :color="getColorStatus('Inactive')">
                            {{item.is_active}}  
                        </CBadge>
                    </td>
                    </template>

                    <template #transaction="{item}">
                        <td>
                            <CButton pressed aria-pressed="true" variant="ghost" class="btn-summary"
                                color="dark" size="sm">
                                Created {{ item.transaction }} Document/s
                            </CButton>
                        </td>
                    </template>

                    <template #number_of_template="{ item }">
                        <td>
                            <CButton color="info" shape="pill" size="sm"
                                @click="showNumberOfTemplateModal(item.user_id)"
                                :disabled="show_template ? true : false"
                            >
                                <font-awesome-icon :icon="show_template ? 'circle-notch' : 'fa-paperclip' " :pulse="show_template ? true : false"/> 
                                {{ item.number_of_template}}
                            </CButton>
                        </td>
                    </template>

                    <template #latest_transaction="{ item }">
                        <td class="p-1">
                            <small>{{ changeDateFormat(item.latest_transaction) }}</small>
                        </td>
                    </template>
                    <template #archive_date="{ item }">
                        <td class="p-1" style="width: 16%;">
                            <p v-if="item.archive_date">
                                <small v-if="item.transaction_within_range != null || item.update_at != null">
                                    Despite being archived on 
                                    <small style="color: red;">{{ changeDateFormat(item.archive_date) }}, </small>  
                                    this user conducted a transaction on   
                                    <small style="color: green;">{{ changeDateFormat(item.transaction_within_range ? item.transaction_within_range : item.update_at) }}.</small>  
                                </small>

                                <small v-else>
                                    There were no transactions within the given period, but archiving occurred on
                                    <small style="color: red;">{{ changeDateFormat(item.archive_date) }}. </small>  
                                </small>
                            </p>
                            <p v-else>
                                <small style="color: green;">
                                    This user is Active.
                                </small>
                            </p>
                        </td>
                    </template>

                </CDataTable>
                <CModal
                    title="Number of Template"
                    color="primary"
                    size="lg"
                    :closeOnBackdrop="false"
                    :show.sync="numberOfTemplateModal"
                    hide-footer
                    >
                    <template #header>
                        <h5> <font-awesome-icon icon="fa-paperclip"/> Number of Template </h5>
                        <CButton 
                            class="pull-right" 
                            color="light" 
                            shape="pill" 
                            size="sm"
                            @click="closeNumberOfTemplateModal()"
                        >
                            <font-awesome-icon icon="times"/>
                        </CButton>
                    </template>
                        <div v-if="numberOfTemplateModal">
                            <CDataTable
                                :items="number_of_template_list"
                                :fields="number_of_template_fields"
                                :table-filter="{ placeholder: 'Your text here.', label: 'Search:' }"
                                :items-per-page="10"
                                border
                                items-per-page-select
                                sorter
                                pagination
                                :loading="this.template_table_loading"
                            >
                                <template slot="no-items-view" v-if="this.template_table_loading">
                                    <center>
                                        <CSpinner style="width:4rem;height:4rem;" color="info" />
                                    </center>
                                </template>
                            </CDataTable>
                        </div>
                        <template #footer>
                            <hr hide>
                        </template>
                    </CModal>
            </CCardBody>
        </CCard>
    </div>
</template>
<style scoped>

</style>
<script>
import CDateRange from '../../components/CDateRange'

export default {

    name: 'BillingReportHome',
    components: {
        CDateRange,
    },
    data() {
        return {
            table_loading: false,
            billing_report_list: [],
            numberOfTemplateModal: false,
            number_of_template_list: [],
            selectedUserId: 0,
            template_table_loading: true,
            exporting_excel: false,
            submit_filter: false,
            remove_filter: 0,
            active_user: '0',
            active_user_loading: false,
            category: ['Creator', 'Approver'],
            selected_category: 'Creator',
            active_user_list: [],
            show_template: false
        }
    },
    computed: {
        billing_report_list_fields: function () {
            let custom_fields = [
                { key: 'name', label: 'Name' },
                { key: 'is_active', label: 'Status' },
                { key: 'transaction', label: 'Overall Transaction/s' },
                { key: 'latest_transaction', label: 'Latest Transaction' },
                { key: 'number_of_template', label: 'No. Of Template/s' },
                { key: 'role', label: 'Category' },
                { key: 'archive_date', label: 'Remarks' }
            ]
            return custom_fields;
        },
        number_of_template_fields: function () {
            let custom_fields = [
                { key: 'name' },
                { key: 'purpose' },
            ]
            return custom_fields;
        },
    },
    methods: {
        showNumberOfTemplateModal(userId) {
            this.selectedUserId = userId;
            this.numberOfTemplateModal = true;
            this.template_table_loading = true;
            this.getNumberOfTemplate(userId);
        },
        getNumberOfTemplate: function (userId) {
            this.$Progress.start()
            this.template_table_loading = true;
            this.show_template = true;
            this.number_of_template_list = [];
            axios.get('report/billing/billing-list/number-of-template/' + userId, { validateStatus: () => true }).then(response => {
                if (response.status == 200) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        let row = {
                            name: response.data.data[i].name,
                            purpose: response.data.data[i].purpose,
                        }
                        this.number_of_template_list.push(row)
                    }
                    this.template_table_loading = false;
                    this.show_template = false;
                    this.$Progress.finish()
                } else {
                    console.error('Error fetching data:', response.statusText);
                }
            })
        },
        async BillingReportExport() {
            try {
                this.exporting_excel = true;
                this.$Progress.start()
                let startDate, endDate;

                if ((this.$refs._CDateRange.range && this.$refs._CDateRange.range.start) && (this.$refs._CDateRange.range && this.$refs._CDateRange.range.end)) 
                {
                    startDate = this.$refs._CDateRange.range.start.getFullYear() + "-" + ("0" + (this.$refs._CDateRange.range.start.getMonth() + 1)).slice(-2) + "-" + ("0" + this.$refs._CDateRange.range.start.getDate()).slice(-2);
                    endDate = this.$refs._CDateRange.range.end.getFullYear() + "-" + ("0" + (this.$refs._CDateRange.range.end.getMonth() + 1)).slice(-2) + "-" + ("0" + this.$refs._CDateRange.range.end.getDate()).slice(-2);

                    const response = await axios.get(`report/billing/export-billing-excel/${startDate}/${endDate}/${this.selected_category.toLocaleLowerCase()}`, {
                        responseType: 'arraybuffer',
                    });

                    const blob = new Blob([response.data]);
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'Billing Report ' + startDate + ' to ' + endDate + ' (' + this.selected_category + ')' + '.xlsx';
                    link.click();
                    
                    this.exporting_excel = false;
                    this.$Progress.finish()
                    this.removeFilter();
                    return this.$swal({
                        toast: true,
                        position: "top-right",
                        showConfirmButton: false,
                        timer: 3000,
                        icon: "success",
                        title: "Download Successfully!",
                        timerProgressBar: true,
                    })
                } else {
                    this.exporting_excel = false;
                    this.$Progress.finish()
                    return this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'error',
                        title: 'Please select date range.',
                        showConfirmButton: false,
                        timerProgressBar: true,
                    });
                }

            } catch (error) {
                console.error('Error exporting Billing Report:', error);
                this.exporting_excel = false;
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Error exporting Billing Report, Please call system administrator',
                    showConfirmButton: false,
                    timerProgressBar: true,
                });
            }
        },
        searchBillingReport: function () {
            let startDate, endDate;
            if ((this.$refs._CDateRange.range && this.$refs._CDateRange.range.start) && (this.$refs._CDateRange.range && this.$refs._CDateRange.range.end)) {
                startDate = this.$refs._CDateRange.range.start.getFullYear() + "-" + ("0" + (this.$refs._CDateRange.range.start.getMonth() + 1)).slice(-2) + "-" + ("0" + this.$refs._CDateRange.range.start.getDate()).slice(-2);
                endDate = this.$refs._CDateRange.range.end.getFullYear() + "-" + ("0" + (this.$refs._CDateRange.range.end.getMonth() + 1)).slice(-2) + "-" + ("0" + this.$refs._CDateRange.range.end.getDate()).slice(-2);
                this.active_user_loading = true;
                this.table_loading = true;
                this.submit_filter = true;
                this.$Progress.start();
                axios.get(`report/billing/filtered-billing-list/${startDate ? startDate + '/' : ''}${endDate ? endDate + '/' : ''}${this.selected_category.toLocaleLowerCase()}`,
                    { validateStatus: () => true }).then(response => {
                        if (response.status == 200) {
                            this.active_user_list = [];
                            this.billing_report_list = [];
                            for (let i = 0; i < response.data.data.length; i++) {
                                let row = {
                                    name: response.data.data[i].name,
                                    is_active: response.data.data[i].is_active,
                                    role: response.data.data[i].role,
                                    archive_date: response.data.data[i].archive_date,
                                    transaction: response.data.data[i].transaction,
                                    number_of_template: response.data.data[i].number_of_template,
                                    user_id: response.data.data[i].user_id,
                                    latest_transaction: response.data.data[i].latest_transaction,
                                    transaction_within_range:response.data.data[i].transaction_within_range,
                                    update_at:response.data.data[i].update_at

                                }
                                this.billing_report_list.push(row)

                                    if (response.data.data[i].is_active === 'Active') {
                                        this.active_user_list.push(response.data.data[i]);
                                    }

                                this.active_user = this.active_user_list.length.toString();
                            } 
                            this.stopLoading();
                            this.$Progress.finish()
                        } else {
                            console.error('Error fetching data:', response.statusText);
                            this.removeFilter();
                            this.stopLoading();
                        }
                    });
            } else {
                this.$Progress.finish()
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please select date range.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
            }
        },
        closeNumberOfTemplateModal: function () {
            this.$Progress.finish()
            this.numberOfTemplateModal = false;
            this.show_template = false;
            this.number_of_template_list = [];

        },
        removeFilter: function () {
            this.billing_report_list = [];
            this.$refs._CDateRange.range = null;
            this.selected_category = 'Creator'
            this.active_user = '0';

        },

        stopLoading: function () {
            this.submit_filter = false;
            this.active_user_loading = false;
            this.table_loading = false;
            this.remove_filter = false;
        },
    },

}
</script>